@tailwind base;
@tailwind components;
@tailwind utilities;
.card {
  opacity: 0;
  transition-duration: 1s ease;
}

.card.active {
  opacity: 1;
  transition-duration: 1s;
  transition: scale(1);
}

.main {
  height: calc(100vh-96px);
  overflow: hidden;
  max-height: calc(100vh-96px);
}